import './Footer.css';

function Footer() {

    return (
        <>
            <footer>
  <div className="container">
    <p>&copy; 2023 Dmm Advocacia. Todos os direitos reservados.</p>
  </div>
</footer>

        </>
    )
}

export default Footer;